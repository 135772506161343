import React, { useState } from 'react';
import { Box, Container, Typography, Link, IconButton, Modal, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import FacebookIcon from '@mui/icons-material/Facebook';
import { X, YouTube } from '@mui/icons-material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { ReactComponent as TikTokIcon } from './images/tiktok.svg';
import logo from './images/logo.jpg';

const FooterLogo = styled('img')(({ theme }) => ({
  height: '90px',
  borderRadius: '15px',
  [theme.breakpoints.down('sm')]: {
    height: '90px',
    borderRadius: '10px',
  },
  [theme.breakpoints.up('md')]: {
    height: '90px',
    borderRadius: '20px',
  },
}));

const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#000000',
  color: '#FFFFFF',
  padding: theme.spacing(4, 0),
  marginTop: theme.spacing(4),
}));

const SocialIcons = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
}));

const FooterLink = styled(Link)(({ theme }) => ({
  color: '#FFFFFF',
  textDecoration: 'none',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  color: 'inherit',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.primary.main,
  },
}));

const PrivacyPolicyModal = ({ open, onClose }) => (
  <Modal
    open={open}
    onClose={onClose}
    aria-labelledby="privacy-policy-title"
    aria-describedby="privacy-policy-description"
  >
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxHeight: '80vh',
        overflowY: 'auto',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
      }}
    >
      <Typography id="privacy-policy-title" variant="h4" component="h2" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography id="privacy-policy-description" sx={{ mt: 2 }}>
        At Family Reunite, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website. Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.

        <Typography variant="h6" component="h3" sx={{ mt: 2 }}>
          Collection of Your Information
        </Typography>
        We may collect information about you in a variety of ways. The information we may collect on the Site includes:
        <ul>
          <li><strong>Personal Data:</strong> Personally identifiable information, such as your name, shipping address, email address, and telephone number, and demographic information, such as your age, gender, hometown, and interests, that you voluntarily give to us when you register with the Site or when you choose to participate in various activities related to the Site, such as online chat and message boards.</li>
          <li><strong>Derivative Data:</strong> Information our servers automatically collect when you access the Site, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Site.</li>
          <li><strong>Financial Data:</strong> Financial information, such as data related to your payment method (e.g., valid credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange, or request information about our services from the Site.</li>
        </ul>

        <Typography variant="h6" component="h3" sx={{ mt: 2 }}>
          Use of Your Information
        </Typography>
        Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Site to:
        <ul>
          <li>Create and manage your account.</li>
          <li>Process your transactions.</li>
          <li>Send you a newsletter.</li>
          <li>Email you regarding your account or order.</li>
          <li>Fulfill and manage purchases, orders, payments, and other transactions related to the Site.</li>
          <li>Monitor and analyze usage and trends to improve your experience with the Site.</li>
        </ul>

        <Typography variant="h6" component="h3" sx={{ mt: 2 }}>
          Disclosure of Your Information
        </Typography>
        We may share information we have collected about you in certain situations. Your information may be disclosed as follows:
        <ul>
          <li><strong>By Law or to Protect Rights:</strong> If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.</li>
          <li><strong>Third-Party Service Providers:</strong> We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.</li>
          <li><strong>Business Transfers:</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
        </ul>

        <Typography variant="h6" component="h3" sx={{ mt: 2 }}>
          Security of Your Information
        </Typography>
        We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.

        <Typography variant="h6" component="h3" sx={{ mt: 2 }}>
          Contact Us
        </Typography>
        If you have questions or comments about this Privacy Policy, please contact us at:
        <address>
          Family Reunite Network<br/>
          Email: familyreunitenetwork@gmail.com
        </address>
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
        <Button onClick={onClose} variant="contained" color="primary">
          Close
        </Button>
      </Box>
    </Box>
  </Modal>
);


const Footer = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const currentYear = new Date().getFullYear();

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  return (
    <FooterContainer>
      <Container maxWidth="lg">
        <Box display="flex" justifyContent="center" mb={2}>
          <FooterLogo src={logo} alt="Family Reunite Logo" />
        </Box>
        <SocialIcons>
          <CustomIconButton href="https://www.facebook.com/familyreunitenetwork?mibextid=ZbWKwL" target="_blank" rel="noopener">
            <FacebookIcon />
          </CustomIconButton>
          <CustomIconButton href="https://x.com/familyreunite?t=ppJFdk1pYzyJEwrOSvJeCQ&s=09" target="_blank" rel="noopener">
            <X />
          </CustomIconButton>
          <CustomIconButton href="https://www.linkedin.com/in/timothy-scott-38548750/" target="_blank" rel="noopener">
            <LinkedInIcon />
          </CustomIconButton>
          <CustomIconButton href="https://www.tiktok.com/@familyreunite?_t=8nMiaH63FqZ&_r=1" target="_blank" rel="noopener">
            <TikTokIcon style={{ fill: 'white', width: '24px', height: '24px' }} />
          </CustomIconButton>
          <CustomIconButton href="https://youtube.com/@familyreunite?si=P_vQL7qJC9GJ6RrI" target="_blank" rel="noopener">
            <YouTube style={{ fill: 'white', width: '24px', height: '24px' }} />
          </CustomIconButton>
        </SocialIcons>
        <Typography variant="body2" align="center" gutterBottom>
          <FooterLink component="button" onClick={handleOpenModal}>
            Privacy Policy
          </FooterLink>
        </Typography>
        <Typography variant="body2" align="center">
          © {currentYear} Family Reunite Network. All rights reserved.
        </Typography>
      </Container>
      <PrivacyPolicyModal open={modalOpen} onClose={handleCloseModal} />
    </FooterContainer>
  );
};

export default Footer;
