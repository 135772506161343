import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Blog from '../components/Blog'; // Ensure the correct path to the Blog component
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const BlogPage = () => {
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Blog | Family Reunite Network</title>
          <meta name="description" content="Read the latest updates, stories, and insights from Family Reunite Network. Stay informed about our efforts in reuniting families and supporting immigration." />
          <meta name="keywords" content="Family Reunite Network blog, family reunion stories, immigration support, latest updates, family support" />
        </Helmet>
        <Navbar />
        <Blog />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default BlogPage;
