import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Loading from './Loading';
import ScrollToTop from './ScrollToTop';
import BlogPage from './pages/BlogPage';

const Home = lazy(() => import('./pages/Home'));
const ContactPage = lazy(() => import('./pages/ContactPage'));
const StumblingInTheDark = lazy(() => import('./pages/StumblingInTheDark'));

function App() {
  return (

    <div className="App">
      <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <ScrollToTop />
        <Routes>
        <Route path="/*" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/contact-page" element={<ContactPage />} />
          <Route path="/stumbling-in-the-dark-looking-for-the-light-switch" element={<StumblingInTheDark />} />
         <Route path="/blog" element={<BlogPage />} />
        </Routes>
      </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
