import React, { useState, useEffect } from 'react';
import { Container, Typography, Paper, Button, Chip, Box, Avatar, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import logo from './images/logo.jpg'; // Ensure the path to your logo image is correct
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { motion } from 'framer-motion';

const BlogContainer = styled(Container)(({ theme }) => ({
  paddingTop: "150px",
}));

const BlogPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(3),
  backgroundColor: '#fff8e1',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 8px 30px rgba(0, 0, 0, 0.2)',
  },
}));

const BlogTitle = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  fontWeight: 'bold',
  color: '#3e2723',
  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
}));

const BlogContent = styled(Typography)(({ theme }) => ({
  color: '#5d4037',
  marginBottom: theme.spacing(2),
}));

const BlogButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#ffab40',
  color: '#3e2723',
  '&:hover': {
    backgroundColor: '#ff9100',
  },
}));

const BlogChip = styled(Chip)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  borderColor: '#3e2723',
  color: '#3e2723',
}));

const BlogAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(12),
  height: theme.spacing(12),
  marginBottom: theme.spacing(2),
  border: '2px solid #ffab40',
  '&:hover': {
    borderColor: '#ff9100',
  },
}));

const NavigationBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: 'white',
  marginBottom: theme.spacing(3),
}));

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    fetch('/data/blogs.json') // Replace with the correct path to your JSON file
      .then(response => response.json())
      .then(data => setBlogs(data))
      .catch(error => console.error('Error fetching the blogs:', error));
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % blogs.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + blogs.length) % blogs.length);
  };

  if (blogs.length === 0) {
    return <Typography variant="h5">Loading...</Typography>;
  }

  const currentBlog = blogs[currentIndex];

  return (
    <HelmetProvider>
      <BlogContainer>
        <Helmet>
          <title>{currentBlog.title} | Family Reunite Network</title>
          <meta name="description" content={currentBlog.content.slice(0, 150) + '...'} />
        </Helmet>
        <Typography color="white" variant="h3" gutterBottom>
          Blog Posts
        </Typography>
        <NavigationBar>
          <IconButton onClick={handlePrevious} disabled={currentIndex === 0}>
            <ArrowBackIcon style={{ color: "white" }}/>
          </IconButton>
          <Typography variant="body1" color="white">
            {currentIndex + 1} / {blogs.length}
          </Typography>
          <IconButton onClick={handleNext} disabled={currentIndex === blogs.length - 1}>
            <ArrowForwardIcon style={{ color: "white" }} />
          </IconButton>
        </NavigationBar>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.5 }}
        >
          <BlogPaper elevation={3}>
            <Box display="flex" justifyContent="center">
              <RouterLink to="/">
                <BlogAvatar src={logo} alt="Logo" />
              </RouterLink>
            </Box>
            <BlogTitle variant="h5" gutterBottom>
              {currentBlog.title}
            </BlogTitle>
            <BlogContent variant="body1">
              {currentBlog.content}
            </BlogContent>
            <BlogChip label="Author - T.J. Scott" variant="outlined" />
            {currentBlog.externalLink && (
              <a href={currentBlog.externalLink} target="_blank" rel="noopener noreferrer">
                <BlogButton variant="contained">Learn More!</BlogButton>
              </a>
            )}
          </BlogPaper>
        </motion.div>
      </BlogContainer>
    </HelmetProvider>
  );
};

export default Blog;
